<template>
  <div>
    <article class="page inquire">
      <div class="content-con">
        <h2 class="page-tit"><em>订单查询</em><span>查询你的订单</span></h2>

        <section class="forms-basic inquire-form">
          <p>
            <input
              type="text"
              id="inputPhone"
              v-model="phoneNo"
              placeholder="请输入你的手机号"
            />
          </p>
          <p class="inquire-action">
            <a class="btn-active" :onclick="SendQuery()">查询</a>
          </p>
        </section>
      </div>
    </article>
  </div>
</template>

<script>
import { GetOrderByOrderId } from "@/service/api";
import router from "../router";

export default {
  name: "Order_Logistics",
  data() {
    return {
      phoneNo: "",
    };
  },
  mounted: function () {
    this.$parent.SetWindowTitle("订单查询");
    $(function () {
      //banner 設定尺寸
      $(".banner-cover").addClass("_lvThr");
      $("#ban1").css("background-image", "url(assets/img/banner_lvThr1.jpg)");
    });
  },
  methods: {
    SendQuery() {
      return "location.href = 'order?phoneNo=" + this.phoneNo + "'";
    },
  },
};
</script>
